import _ from 'underscore'
import $ from 'jquery'
import {
    attachThumbSrc,
    formatDate,
    postRenderImages,
    serverKeepAlivePing,
    activateLinks
} from 'util/twistle'
import {
    raiseAlert
} from "util/alert_util"

import { isHandheld } from 'util/breakpoints'
import { renderTemplate } from 'util/template_renderer'
import { localize } from 'util/localize'

import { ajax } from 'util/ajax'
import DialogView from 'app/views/dialog_view'

App.FormSubmissionDialogView = DialogView.extend({
    className: "formsubmission_dialog",
    initialize: function(options) {
        const self = this;
        self.options = _.extend({}, self.options, options, {
            title: "",
            width:Math.min($(window).width()-40,620),
            height:"auto",
            trueModal:!self.options.submissionId,
            dialogClass:"dialog_panel form_submission_dialog_panel",
            overlayClass: "attachment_dialog_overlay",
            nextButtonText: localize("CustomForm-submit", "Submit"),
            nextButtonClass: isHandheld() ? "tws-btn--primary" : undefined,
            backButtonTitle: isHandheld() ? localize("UIHelpers-back", "Back") : undefined,
        });

        DialogView.prototype.initialize.call(self, self.options);

        if(!self.isOnStack)
        {
            self.render();
        }

    },

    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {
            'change input': 'handleInputChanged',
            'keyup input': 'handleInputChanged',
            'keyup textarea': 'handleInputChanged',
            'click .submit_form': 'submitForm',
            'click .form_submission_manage_button': 'manageForm',
            'click .form_submission_recalc_score': 'recalculateScore',
            'click .form_submission_print_button': 'printForm',
            'click .form_all_submissions_button': 'showSubmissions',
            'click .play_recording':'playRecording',
            'click .sign_form_button':'addSignature',
            'click .scannerfield_button':'captureBarcode'
        });
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        if (!self.options.modal && self.options.draggable) {
            App.bind("app:close_draggable_dialogs", self.close, self);
        }

        self.options.showHeader = self.options.alwaysDisplayHeader || !self.options.inlineDisplay;

        // don't show the "submit" button in the navbar until we know its relevant
        self.$(".detail_next_button").hide();

        self.getEl().spin();

        self.getEl().on("scroll", self._formScrolled);

        // if viewing a live preview of a non-real conversation (from the workflow editor - show it immediately)
        if(self.options.dummyPreviewFormData){
            self.renderForm(self.options.dummyPreviewFormData);
            return self;
        }

        // in certain circumstances (public "self enroll" data capture forms) we view/submit the form to a
        // completely separate endpoint
        let viewUrl = self.options.anonymousSubmissionMode ? "/form/ViewAnon" : "/form/View";

        ajax.request(viewUrl, {
            form_definition_id: self.options.formId,
            form_submission_id: self.options.submissionId,
            attached_to_note_id: self.options.responseToNoteId,
            client_context_username: self.options.clientContextUsername,
            include_standardized_score_config: true
        }, [], function (resp) {
            self.renderForm(resp);
        }, false, function(){
            setTimeout(function(){
                self.hide();
            },1200);
        });

        $(document).bind('click keyup mousemove', self.handleActivity);

        return self;
    },

    printForm: function(){
        var self = this;
        if(!self.isOnStack)
        {
            self.hide();
            setTimeout(function(){
                App.openDialogView(new App.FormSubmissionDialogView(_.extend(self.options,{alwaysPush:true})));
            }, 100);
        }
    },

    _formScrolled: _.debounce(function(){
        let self = this, $nav = self.getStackNavBar(), navbarHeight = 40;

        if(!$nav){
            // we only hide the navbar upon scrolling down on handheld - thus if there is no navbar (because we are
            // not in a stacked view, bail here;
            return;
        }

        let currentPos = self.getEl().scrollTop();

        if(!self.scrollPos){
            self.scrollPos = 0;
        }

        if(currentPos > navbarHeight && currentPos > self.scrollPos){
            // we scrolled down at least one navbar height, hide the header
            $nav.hide("slide", {direction:"up", duration:150});
        } else if(currentPos < navbarHeight || (currentPos + navbarHeight) < self.scrollPos){
            // we scrolled up at least roughly the height of the nav bar (or are near the top), show the header
            $nav.show("slide", {direction:"up", duration:150});
        }
        self.scrollPos = currentPos;

    }, 50),

    recalculateScore: function (evt) {
        var self = this, $targ = $(evt.currentTarget), params = {
            form_submission_id: self.formDef.submission.id
        };

        ajax.request("/form/Recalc", params, [], function () {
            App.trigger("app:form_submission_score_recalculated");
            self.hide();
        }, false, false, $targ);
    },

    showSubmissions: function(){
        var self = this;
        if(!self.isOnStack)
        {
            self.hide();
            setTimeout(function(){
                var args = {
                    formId: self.formDef.id,
                    fromFormDefinitionView: false
                };

                if (self.formDef.submission) {
                    let userToShow = (self.formDef.submission?.workflow_execution_target_actor_account ||
                        self.formDef.submission?.account);
                    args.filterToSubmissionsForUsername = userToShow?.username;
                }
                else {
                    args.currentUserSubmissionsOnly = true;
                }

                App.openDialogView(new App.FormDefinitionSubmissionsDialogView(args));
            }, 100);
        }
    },

    renderForm: function(formDef)
    {
        var self = this;
        if(!self.rendered)
        {
            self.formDef = self.augmentFields(formDef);

            self.options.readOnly = (self.formDef.submission && !self.formDef.allow_amended_submissions) || self.options.readOnly;

            if(formDef.prevent_submission_workflow_closed){
                self.options.readOnly = true;
            }

            if(!self.options.currentUILanguage
                && self.formDef.supported_languages?.length > 0
                && App.account.get("locale"))
            {
                // see if we can match an alternate language with region
                var matchedLangIdx = _.indexOf(self.formDef.supported_languages, App.account.get("locale"));

                if(matchedLangIdx === -1)
                {
                    matchedLangIdx = _.indexOf(self.formDef.supported_languages, App.account.get("locale").substr(0,2));
                }

                if(matchedLangIdx > -1)
                {
                    self.options.currentUILanguage = self.formDef.supported_languages[matchedLangIdx];
                }

            }

            self.getEl().spin({stop: true});
            self.setTitle("");

            formDef.localized_title = formDef.title_languages?.[self.options.currentUILanguage] || formDef.title;
            formDef.localized_client_title = formDef.extended_props?.client_title_languages?.[self.options.currentUILanguage] || formDef.extended_props?.client_title;
            formDef.localized_client_instructions = formDef.extended_props?.client_instructions_languages?.[self.options.currentUILanguage] || formDef.extended_props?.client_instructions;
            formDef.hide_submit_button = formDef.extended_props?.hide_submit_button;

            self.getEl().html(
                renderTemplate("form_submission_dialog_template",
                    _.extend(self.options,formDef),
                    App.account.get("locale"))
            );
            self.$fieldWrap = self.$(".form_field_wrap .form_submission_fields_container");

            self.manipulatedFields = {}; // tracks which fields have captured user input and thus cannot be preselected.
            self.preselectActivatedFields = {};

            _.each(
                self.formDef.fields, function (field, idx)
                {
                    if (!field.requires_field_option || field.requires_field_option.length === 0)
                    {
                        field.idx = idx;
                        self._addField(self.$fieldWrap, field);
                    }
                    if(self.options.readOnly && self.options.asTemplate)
                    {
                        _.each(field.options||[],function(option){
                            self._renderChildFields(field, option.id, option.label, self.formDef.conditional_field_state);
                        });
                    }

                    if(field.preconditions?.conditions?.length > 0){
                        // if this is a conditional field, we hide it unless it's initial conditional state is true
                        // (typically because of saved draft values) *or* we are showing a submission and the field
                        // has a submitted value
                        const submittedValue = self.formDef.submission?.values?.[field.id] || {},
                            hasSubmittedValue = (submittedValue.value?.length > 1
                                || submittedValue.field_option_ids?.length > 0
                                || submittedValue.attachments?.length > 0),
                            shouldHideConditionalField = !hasSubmittedValue && !self.formDef.conditional_field_state?.[field.id]

                        if(shouldHideConditionalField) {
                            self.$(`.form_field_wrap_${field.id}`).hide();
                        }

                    }
                }
            );

            var org = App.account.getOrgById(self.formDef.organization);
            var canManageForm = org && (!org.admin_control_membership || org.admin);
            if (!self.options.fromFormDefinitionView && canManageForm
                && !self.isOnStack)
            {
                self.setTitleButton("form_submission_manage_button", undefined, self.manageForm, "manage this form");
            }

            if(self.formDef.submission && !self.isOnStack)
            {
                self.setTitleButton("form_submission_print_button", undefined, self.printForm, "printable view");
            }

            if (self.formDef.calculate_total_score && self.formDef.submission && !self.isOnStack) {
                self.setTitleButton("form_submission_recalc_score_button", undefined, self.recalculateScore,
                    "recalculate score");
            }

            if(self.formDef.allow_multiple_submissions && !self.isOnStack)
            {
                var submissionToolTip = "show all of my submissions";
                let userToShow = (self.formDef.submission?.workflow_execution_target_actor_account ||
                                  self.formDef.submission?.account);
                if (userToShow && userToShow.id !== App.account.get("id")) {
                    submissionToolTip = "Show Submissions For " + userToShow.formalname;
                }

                self.setTitleButton("form_all_submissions_button", undefined, self.showSubmissions, submissionToolTip);
            }


            if(self.formDef.submission
                && self.formDef.allow_amended_submissions
                && !self.options.readOnly
                && !self.isOnStack){
                // if you are amending a past submission in an "inline" mode - move the submission header beneath
                // the submit button such that the user is aware of the past submission
                self.$(".form_submission_header").insertAfter(self.$(".form_field_wrap"));
            }

            if (self.options.readOnly)
            {
                self.$fieldWrap.addClass("readonly");
                self.$(".detail_next_button").hide();
                self.$(".form_submission_manage_button").addClass("has_submission");
                self.disableProgressIndicator();
            }
            else {
                self.$(".detail_next_button").show();
                self.validateForm();
            }

            if(formDef.prevent_submission_workflow_closed){
                activateLinks(self.getEl(), ".prevent_submission_workflow_closed_container");
            }

            self.reposition();
            self.rendered = true;

            // Firefox does not show some radio-buttons as checked when they are not set as such from Javascript.
            self.getEl().find("[checked=checked]").prop("checked", true);

            // if we are being asked to submit a specific field, hide all others, and the navbar
            if(self.options.smsFormFieldId){
                let smsField = self.formDef.fields.find(f => f.id.toString() === self.options.smsFormFieldId);
                self.$(".tws_form_field").hide();
                self.$(".detail_next_button").hide();
                if(smsField.field_type === "digsig"){
                    // just open the signature pad
                    self._addSignature(smsField.id);
                }
                else {
                    // show the attach uploader
                    self.$(`.form_field_wrap_${self.options.smsFormFieldId}`).show();
                    // and the submit button
                    self.$("button.submit_form").hide();
                }
            }

            if (self.formDef.hide_submit_button) {
                self.$(".detail_next_button").hide();
            }

            // optionally broadcast a onFormRendered callback
            if(self.options.onFormRendered){
                self.options.onFormRendered(self.formDef);
            }
        }
    },

    manageForm:function()
    {
        var self = this;
        self.hide();
        App.trigger(App.Events.GET_ATTACHMENT,self.formDef.attachment_ref.seqnum);
    },

    augmentFields:function(formDef)
    {
        var self = this;

        // see FormDisplayMixin
        self._baseAugmentForm(formDef);

        if(formDef.submission)
        {
            formDef.submission = self._baseAugmentSubmission(formDef, formDef.submission);
        }
        return formDef;
    },

    handleInputChanged: function(evt) {
        var self = this;
        var fieldId;
        var optionId;
        //for seeing if we can do any preselection
        if(evt && evt.currentTarget)
        {
            var $targ = $(evt.currentTarget);

            if ($targ.data("option_id"))
            {
                optionId = $targ.data("option_id");
                fieldId = $targ.closest(".form_field_row").data("field_id");

            }
        }
        self.validateForm(optionId, fieldId, true)
    },

    validateForm: function(optionId, fieldId, inputChanged) {
        const self = this;
        var didRenderChildFields;
        var isValid = true;

        if (fieldId)
        {
            self.manipulatedFields[fieldId] = true;
        }

        // determine which options are chosen on the form, and make sure dependent options are shown
        _.each(self.formDef.fields,function(field)
        {
            if (field.options)
            {
                var hasChildFieldsVisible = false;
                let disableOtherCheckboxes = false;
                switch (field.field_type)
                {
                    case "select": {
                        let selectedItemValue = self.$(`.field_${field.id}`).twistle_dropdown("getValue").value;
                        let allItemValues = self.$(`.field_${field.id}`).twistle_dropdown("getItemValues");
                        if (selectedItemValue) {
                            hasChildFieldsVisible = self._renderChildFields(field, selectedItemValue, null, self.formDef.conditional_field_state);
                            didRenderChildFields = true;
                        }
                        if (allItemValues.length) {
                            allItemValues
                                .filter(itemValue => itemValue != selectedItemValue)
                                .forEach(itemValue => { self.$(`.child_fields_for_option_${itemValue}`).hide() });
                        }
                        break;
                    }
                    case "slider":
                    case "autocomplete":
                        var val = self.$("input[name=field_" + field.id + "]").val();
                        if (val && val.length > 0)
                        {
                            didRenderChildFields = self._renderChildFields(field, val, null, self.formDef.conditional_field_state);
                            if (didRenderChildFields) {
                                hasChildFieldsVisible = true;
                            }
                        }
                        else
                        {
                            self.$(".child_fields_for_option_" + val).remove();
                        }
                        break;
                    case "radio":
                    case "checkboxes":
                        self.$(".field_" + field.id + " input").each(
                            function (idx, formEl) {
                                var $formEl = $(formEl), optionId = $formEl.data("option_id");
                                if ($formEl.is(":checked"))
                                {
                                    if(self._renderChildFields(field, optionId, null, self.formDef.conditional_field_state)){
                                        didRenderChildFields = true;
                                        hasChildFieldsVisible = true;
                                    }

                                }
                                else
                                {
                                    self.$(".child_fields_for_option_" + optionId).remove();
                                }
                                if(field.field_type === "checkboxes" && $formEl.data("disable_other_options")){
                                    const otherCheckboxes = self.$(`.field_${field.id} input:not([data-option_id='${optionId}'])`)
                                    if($formEl.is(":checked")){
                                        // clear others
                                        otherCheckboxes.prop("checked", false);
                                        // clear other child fields
                                        otherCheckboxes.each((_, otherCheckbox) => {
                                            const option_id = self.$(otherCheckbox).data("option_id");
                                            if (option_id) {
                                                self.$(".child_fields_for_option_" + option_id).remove();
                                            }
                                        });
                                        // and disable
                                        otherCheckboxes.prop("disabled", $formEl.is(":checked"));
                                        disableOtherCheckboxes = true;
                                    } else if (!disableOtherCheckboxes) {
                                        otherCheckboxes.prop("disabled", false);
                                    }
                                }
                            }
                        );
                        break;
                    default:
                        break;
                }

                if(!hasChildFieldsVisible)
                {
                    self.$(".form_field_wrap_"+field.id + " .child_fields").hide();
                }

            }



            // if this is the field we manipulated, preselect the other values
            if(!self.options.asOptionMatchesFor && fieldId && optionId && fieldId === field.id)
            {
                var option = _.findWhere(field.options,{id:optionId});

                if(self.preselectActivatedFields[fieldId])
                {
                    // this field already triggered preselections - clear those fields
                    _.each(self.preselectActivatedFields[fieldId],function(preselectedFieldId){
                        self.$(".form_field_wrap_"+ preselectedFieldId + " .child_fields").hide();
                        var field = _.findWhere(self.formDef.fields,{id:preselectedFieldId});
                        if(field)
                        {
                            self._setSelectedOptionsOrValue(field, "");
                        }
                    });
                    self.preselectActivatedFields[fieldId] = undefined;
                }

                // now select any field->options associated with the selected option
                if(option.preselect_values && option.preselect_values.length > 0)
                {
                    var preselectValues = JSON.parse(option.preselect_values);

                    _.each(preselectValues, function(field_option_ids_or_value, preselectFieldId){
                        var preselectFieldIdInt = parseInt(preselectFieldId,10);
                        if(!self.manipulatedFields[preselectFieldIdInt] && preselectFieldIdInt !== fieldId)
                        {
                            if(!self.preselectActivatedFields[fieldId])
                            {
                                self.preselectActivatedFields[fieldId] = [];
                            }
                            self.preselectActivatedFields[fieldId].push(preselectFieldIdInt);

                            var field = _.findWhere(self.formDef.fields,{id:preselectFieldIdInt});
                            if(field)
                            {
                                self._setSelectedOptionsOrValue(field, field_option_ids_or_value);
                            }
                        }
                    });
                }

            }
        });

        // otherwise not doing much client-side val yet
        self.$(".submit_form").attr("disabled", !isValid);
        if(didRenderChildFields)
        {
            self.reposition();
        }

        // potentially save a draft
        if(inputChanged && self.options.responseToNoteId){
            self.saveDraft();
        }
        // update the progress indicator
        self.updateProgressIndicator();

        if (self.options.getClinicalValueData && self.formDef.id) {
            ajax.request('/form/PreviewFormState', {
                test_clinical_props: self.options.getClinicalValueData(),
                form_definition_id: self.formDef.id,
                draft_value_data: self.gatherFieldValues(true, true)
            }, [], function(resp) {
                // hide/show conditional fields
                Object.entries(resp || {}).forEach(([fieldId, show]) => {
                    self.$(`.form_field_wrap_${fieldId}`).toggle(show);
                });
                if ("form_score" in resp) {
                    self.options.updateScorePreview(resp.form_score);
                }
                self._toggleSubmitButtonSpinner(false);
            }, false)
        }
    },

    updateProgressIndicator: _.throttle(function(){
        let self = this;

        if(self.options.asTemplate || self.options.fullyPreventDialog || self.options.readOnly) {
            // only show a progress bar when it is legit someone filling out a form, and not in "inline" display
            // mode (embedded within a conversation or someone starting a workflow)
            self.disableProgressIndicator();
            return;
        }

        let org = App.account.getOrgById(self.formDef.organization);
        if(org?.extended_props?.disable_form_progressbar){
            // orgs can opt-out of the progress bar
            self.disableProgressIndicator();
            return;
        }

        let fieldValues = self.gatherFieldValues(true),
            totalRequiredTopLevelFields = 0,
            completedRequiredTopLevelFields = 0;

        self.formDef.fields.forEach(field => {
           if(field.required && !field.requires_field_option){
               totalRequiredTopLevelFields +=1;
               if(fieldValues[field.id]?.value?.length > 0 || fieldValues[field.id]?.field_option_ids?.length > 0){
                   completedRequiredTopLevelFields += 1;
               }
           }
        });

        if(!self.$progressWrap){
            self.$progressWrap = self.$(".form_submission_progress_indicator_wrap");
            // on mobile, progress bar is always at the top (above the navigation bar)
            let progressBarTop = !isHandheld() ? self.getEl().position().top : 0;
            self.$progressWrap.show().appendTo(self.getEl().parent()).css("top", progressBarTop);
            self.getEl().css("margin-top", self.$progressWrap.height() + 2);
        }

        let completedPercent = parseInt( (completedRequiredTopLevelFields/totalRequiredTopLevelFields) * 100, 10);
        self.$progressWrap.find(".form_submission_progress_indicator_bar").transition({width:`${completedPercent}%`});

    }, 500, {leading: true, trailing: true}),

    disableProgressIndicator: function(){
        let self = this;
        // compress the space we made for the progress indicator when we are on the stack
        if(self.isOnStack){
            self.$el.addClass("form_submission_without_progress");
        }
    },

    saveDraft: _.throttle(function(){
        let self = this;
        // if viewing a live preview of a non-real conversation (from the workflow editor - prevent saving draft)
        if (self.options.dummyPreviewFormData) {
            return;
        }
        self._toggleSubmitButtonSpinner(true);
        ajax.request('/form/SaveDraftSubmission', {
            form_definition_id: self.formDef.id,
            response_to_note_id: self.options.responseToNoteId,
            draft_value_data: self.gatherFieldValues(true, true)
        }, [], function(resp){
            // hide/show conditional fields
            Object.entries(resp?.conditional_field_state || {}).forEach(([fieldId, show]) => {
                self.$(`.form_field_wrap_${fieldId}`).toggle(show);
            });
            self._toggleSubmitButtonSpinner(false);
        }, false)
    }, 1000, {leading: true, trailing: true, maxWait: 10000}),

    _toggleSubmitButtonSpinner: function(isLoading) {
        const self = this;
        self.$(".submit_form").prop("disabled", isLoading);
        self.$(".submit_form").toggleClass("loading", isLoading);
    },

    submitForm: function(evt) {
        const self = this;
        evt?.preventDefault();

        // if viewing a live preview of a non-real conversation (from the workflow editor - prevent submission)
        if (self.options.dummyPreviewFormData) {
            return;
        }

        var fieldVals = {}, params = {
            form_definition_id:self.formDef.id
        };

        if(self.options.responseToNoteId)
        {
            params.response_to_note_id = self.options.responseToNoteId;
        }

        if(self.formDef.submission && self.formDef.allow_amended_submissions)
        {
            params.submitted_form_id = self.formDef.submission.id;
        }

        if(self.options.asStartWorkflow)
        {
            params.as_start_workflow_def_id = self.options.asStartWorkflow;
        }

        if(self.options.asConfigureWorkflow)
        {
            params.as_configure_workflow_def_id = self.options.asConfigureWorkflow;
        }

        if(self.options.asTrackingDataForWorkflowExecutionId)
        {
            params.as_tracking_data_for_workflow_execution_id = self.options.asTrackingDataForWorkflowExecutionId;
        }

        fieldVals = self.gatherFieldValues(true);

        params.value_data = JSON.stringify(fieldVals);

        self.$(".error").removeClass("error");
        self.$(".error_display").html("");

        if(self.options.asOptionMatchesFor)
        {
            // not a real submission - just need to know what was selected
            App.trigger("app:form_option_matches_set", self.options.asOptionMatchesFor,
                        fieldVals, self.options.canRequireAllOptionMatches && self.$("input[name=option_matches_require_all]").is(":checked"));
            self.hide();
            return;
        }

        let submitUrl = self.options.anonymousSubmissionMode ? "/form/SubmitAnon" : "/form/Submit";

        ajax.request(submitUrl, params, [], function(submitResponse) {
            if(self.model && self.model.get("convseq"))
            {
                App.trigger("push:conversation_updated", self.model && self.model.get("convseq"), false, false, true); // cause a self update
            }
            else
            {
                App.trigger("app:form_updated", self.formDef);
            }

            if(self.options.inlineDisplay)
            {
                // trigger a full refresh
                self.rendered = false;

                // this is an edge case - but if a staff member submits a form in the context of a workflow on behalf
                // of a patient, we'll get a mismatching "by_user" (or note author) in our submission response.
                // When this happens, forcefully load that same submission such that user immediately sees
                // what they submitted (otherwise it kinda feels like a black hole)
                if( (submitResponse.byuser && submitResponse.byuser !== App.account.get("id")) ||
                    (submitResponse.note && submitResponse.note.author && submitResponse.byuser
                        !== App.account.get("username"))){
                    self.options.submissionId = submitResponse.id;
                }

                self.render();
            }
            else
            {
                self.hide();
            }
            App.trigger("app:form_submitted", self.formDef, submitResponse, self.model && self.model.get("convseq"), self.options.responseToNoteId, fieldVals);

            if(self.options.asTrackingDataForWorkflowExecutionId){
                App.trigger("app:workflow_execution_tracking_data_submitted",
                    self.options.asTrackingDataForWorkflowExecutionId,
                    submitResponse);
            }

            if(self.options.onSubmitCallback)
            {
                self.options.onSubmitCallback(submitResponse);
            }

        }, true, function(xhr, jsonError){
            var handledError;
            if(jsonError.error === "SubmissionError")
            {
                if(jsonError.data)
                {
                    _.each(jsonError.data||[],function(fieldId){
                        var $fieldWrap = self.$(".form_field_wrap_"+fieldId);
                        if($fieldWrap.length)
                        {
                            let errorText = jsonError.field_errors?.[fieldId] || jsonError.text || "Incorrect response";
                            $fieldWrap.find("input,textarea,.twistle_dropdown").addClass("error");
                            $fieldWrap.find(".error_display").html(errorText);
                            handledError = true;
                            self.getEl().scrollTo($fieldWrap);
                        }
                    });
                }
                else if(jsonError.text === "This form does not allow blank submissions")
                {
                    raiseAlert("This form requires at least one field to be filled out!");
                    handledError = true;
                }
            }
            return handledError;

        }, $(evt.currentTarget));
    },

    submitSMSField: function(){
        let self = this,
            smsField = self.formDef.fields.find(f => f.id.toString() === self.options.smsFormFieldId),
            attachments = self.gatherFieldValues(true)[self.options.smsFormFieldId]?.attachments || [],
            params = {
            form_definition_id: self.formDef.id,
            response_to_note_id: self.options.responseToNoteId,
            field_id: self.options.smsFormFieldId,
            attachments: attachments.map(a => a.seqnum).join(",")
        }
        self.getEl().html("").spin();

        ajax.request("/form/SubmitSMSFormFieldAttachment", params, [],
            function(submitResponse) {
            App.trigger("app:form_submitted", self.formDef,
                {smsFormAttachmentDigSig: smsField.field_type === "digsig"});
        });

    },

    gatherFieldValues: function(includeAttachments, forDraft){
        let self=this, fieldVals={};
        _.each(self.formDef.fields,function(field)
        {
            if(!forDraft && !self.$(`.form_field_wrap_${field.id}`).is(":visible")){
                // don't submit non-visible fields (we do draft-save them just in case the user changes their mind
                // and previously filled fields get displayed)
                return;
            }
            if(field.options)
            {
                switch(field.field_type)
                {
                    case "select":
                    case "slider":
                    case "autocomplete":
                        var selectOpts = [], val = self.$("input[name=field_"+field.id+"]").val();
                        if(val !== undefined && val.length > 0)
                        {
                            selectOpts = [parseInt(val, 10)];
                        }
                        fieldVals[field.id] = {field_option_ids:selectOpts};
                        break;
                    case "radio":
                    case "checkboxes":
                        var options = [];
                        self.$(".field_"+field.id+" input").each(function(idx,formEl){
                            var $formEl = $(formEl);
                            if($formEl.is(":checked"))
                            {
                                options.push(parseInt($formEl.data("option_id"), 10));
                            }
                        });
                        fieldVals[field.id] = {field_option_ids:options};
                        break;
                }
            }
            else
            {
                // look for the text based input element
                var $input = self.$("input[name=field_" + field.id + "],textarea[name=field_" + field.id + "]"),
                    fieldValue;

                // if it's part of the dom, grab the text value (unless it's a date picker)
                if ($input && $input.length !== 0) {
                    fieldValue = (field.datefield && !field.extended_props?.date_text_input) ? $input.tws_datePicker("getDateVal") : $input.val();
                }

                fieldVals[field.id] = {value: fieldValue || ""};
            }
            if(includeAttachments && (field.field_type === "attachments" || field.field_type === "digsig")){
                // include meta info about attachments (including e-signature) to this field
                fieldVals[field.id].attachments = self.$(
                        `.field_attachments_wrap_${field.id} .fileElement,
                                 .field_digsig_wrap_${field.id} .fileElementNoteDisplay`).map(
                            function(i, el){
                                // return only the bare minimum attach props we need to store alongside the draft
                                return _.pick($(el).data("fileData"), ["seqnum", "description", "type", "adddate"]);
                            }).get();
            }
        });
        return fieldVals;
    },

    handleNextButton:function(evt){
        var self = this;
        self.submitForm(evt);
    },

    playRecording:function(evt){
        var self = this;
        var url = "/voice/RecordedValue?";
        url += "field_id=" + $(evt.currentTarget).data("field_id");
        url += "&form_definition_id=" + self.formDef.id;
        url += "&form_submission_id=" + self.options.submissionId;

        window.open(url);
    },

    addSignature:function(evt) {
        let self = this;
        self._addSignature($(evt.currentTarget).data('id'));
    },

    _addSignature:function(fieldId){
        let self = this;
        let field = self.formDef.fields.find(f => f.id == fieldId);

        App.openDialogView(new App.FormSubmissionsDigSigDialogView(
            {
                hideBackButtonOnStack: self.options.smsFormFieldId,
                pushImmediate: self.options.smsFormFieldId,
                isRequired: field.required,
                onSign:function(attachment){
                    self.addSignatureComplete(fieldId, attachment);
                }
            }

        ));
    },

    captureBarcode: function (evt)
    {
        var self = this,
            fieldName = $(evt.currentTarget).data("fieldname"),
            $field = self.$("input[name=" + fieldName + "]");

        // todo - implement barcode / qr code field for the web

    },

    addSignatureComplete:function(fieldId, attachment, showAuditBy)
    {
        var self = this;

        var fieldWrap = self.$(".field_digsig_wrap_"+fieldId);
        fieldWrap.find(".sign_form_button").text(
            localize("CustomForm-changeSignature", "Change Signature")
        );
        var $attachWrap = fieldWrap.find(".field_attachments_container")
            $attachWrap.empty();

        var $attach = $('<a class="fileElementNoteDisplay"></a>');
        var thumbURL = attachThumbSrc(attachment, "prf", undefined);
        var height = 50;
        var $img = $("<img>", {
            style: `max-width:400px;max-height:200px;min-height:${height}px;`,
            src: thumbURL,
            class: "attach_thumb",
            ["data-type"]: attachment.type,
            ["data-seqnum"]: attachment.seqnum,
            ["data-size"]: "prf"
        });
        $attach.data('fileData', attachment);
        $attach.append($img);
        $attach.click(attachment, function(evt) {
            App.trigger(App.Events.GET_ATTACHMENT, evt.data.seqnum);
        });
        $attach.appendTo($attachWrap);
        fieldWrap.find("input[name=field_"+fieldId+"]").val(attachment.seqnum);

        if(showAuditBy) {
            fieldWrap.find(".digsig_audit_display").text(
                `Signed on ${formatDate(attachment.adddate)} (${attachment.adddate}) by ${showAuditBy}`);
        } else {
            fieldWrap.find(".digsig_audit_display").text("");
            fieldWrap.find(".digsig_audit_display").height("0.5rem");
        }

        postRenderImages(self.$el);

        self.validateForm(undefined, undefined, true);

        if(self.options.smsFormFieldId === fieldId.toString()){
            // auto-submit the field for sms delivered forms
            self.submitSMSField();
        }

    },

    handleActivity: _.throttle(function() {
        serverKeepAlivePing();
    }, 1000 * 60 * 5 /* 5 min */, {leading: false})
});


// mixin form display helpers
_.extend(App.FormSubmissionDialogView.prototype, App.FormDisplayMixin);
// mixin the language selection  helpers / events
_.extend(App.FormSubmissionDialogView.prototype, App.LanguageSelectionMixin);
